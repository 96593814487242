@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?s3dc1s');
  src:  url('fonts/icomoon.eot?s3dc1s#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?s3dc1s') format('woff2'),
    url('fonts/icomoon.ttf?s3dc1s') format('truetype'),
    url('fonts/icomoon.woff?s3dc1s') format('woff'),
    url('fonts/icomoon.svg?s3dc1s#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-up:before {
  content: "\e91c";
}
.icon-play .path1:before {
  content: "\e91a";
  color: rgb(255, 255, 255);
}
.icon-play .path2:before {
  content: "\e91b";
  margin-left: -1.0126953125em;
  color: rgb(31, 31, 31);
}
.icon-check:before {
  content: "\e918";
}
.icon-attention .path1:before {
  content: "\e915";
  color: rgb(253, 211, 1);
}
.icon-attention .path2:before {
  content: "\e916";
  margin-left: -1.154296875em;
  color: rgb(31, 31, 31);
}
.icon-attention .path3:before {
  content: "\e917";
  margin-left: -1.154296875em;
  color: rgb(31, 31, 31);
}
.icon-addFile .path1:before {
  content: "\e912";
  color: rgb(253, 211, 1);
}
.icon-addFile .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-filter:before {
  content: "\e911";
  color: #6c6c6c;
}
.icon-sort:before {
  content: "\e90e";
  color: #6c6c6c;
}
.icon-plus:before {
  content: "\e919";
  color: #707070;
}
.icon-search:before {
  content: "\e913";
  color: #6c6c6c;
}
.icon-quastion .path1:before {
  content: "\e90f";
  color: rgb(108, 108, 108);
}
.icon-quastion .path2:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(253, 211, 1);
  opacity: 0.97;
}
.icon-pin:before {
  content: "\e90d";
  color: #fdd301;
}
.icon-okIcon:before {
  content: "\e90a";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-close:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-instagram:before {
  content: "\e905";
}
.icon-linkedin:before {
  content: "\e906";
}
.icon-telegram:before {
  content: "\e908";
}
.icon-twitter:before {
  content: "\e909";
}
.icon-circle-arrow-left .path1:before {
  content: "\e90b";
  color: rgb(253, 211, 1);
}
.icon-circle-arrow-left .path2:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-login:before {
  content: "\e907";
}
